import { EmployeeModel } from 'src/app/models/employee-model';

export class EmployeesBlueprint {
    data: EmployeeModel[] = [{
        'id': 'bez2jl9hh2',
        'name': 'Святослав Заєць',
        'displayName': 'Святослав',
        'photoUrl': 'https://s3.eu-north-1.amazonaws.com/images.fattonysbarbers.com/employees_new_photo/bez2jl9hh2.png',
        'metadata': {
            'name': {
                'uk': 'Святослав',
                'en': 'Svyatoslav'
            }
        }
    }, {
        'id': 'trubf2ouq2',
        'name': 'Олесь Шевчук',
        'displayName': 'Олесь',
        'photoUrl': 'https://s3.eu-north-1.amazonaws.com/images.fattonysbarbers.com/employees_new_photo/trubf2ouq2.png',
        'metadata': {
            'name': {
                'uk': 'Олесь',
                'en': 'Oles'
            }
        }
    }, {
        'id': '6722fdrxny',
        'name': 'Дмитро Чернов',
        'displayName': 'Дмитро',
        'photoUrl': 'https://s3.eu-north-1.amazonaws.com/images.fattonysbarbers.com/employees_new_photo/6722fdrxny.png',
        'metadata': {
            'name': {
                'uk': 'Дмитро',
                'en': 'Dmytro'
            }
        }
    }, {
        'id': 'qzjqzibj6c',
        'name': 'Олександр Агапов',
        'displayName': 'Олександр',
        'photoUrl': 'https://s3.eu-north-1.amazonaws.com/images.fattonysbarbers.com/employees_new_photo/qzjqzibj6c.png',
        'metadata': {
            'name': {
                'uk': 'Олександр',
                'en': 'Olexandr'
            }
        }
    }, {
        'id': 'gzkvx30rk8',
        'name': 'Володимир Кухта',
        'displayName': 'Володимир',
        'photoUrl': 'https://s3.eu-north-1.amazonaws.com/images.fattonysbarbers.com/employees_new_photo/gzkvx30rk8.png',
        'metadata': {
            'name': {
                'uk': 'Володимир',
                'en': 'Volodymyr'
            }
        }
    }, {
        'id': '2qgnjm4tcp',
        'name': 'Володимир Бейкер',
        'displayName': 'Володимир Бейкер',
        'photoUrl': 'https://s3.eu-north-1.amazonaws.com/images.fattonysbarbers.com/employees_new_photo/2qgnjm4tcp.png',
        'metadata': {
            'name': {
                'uk': 'Володимир Бейкер',
                'en': 'Volodimir Beyker'
            }
        }
    }, {
        'id': '712761ff06',
        'name': 'Сергій',
        'displayName': 'Сергій',
        'photoUrl': 'https://s3.eu-north-1.amazonaws.com/images.fattonysbarbers.com/employees_new_photo/712761ff06.png',
        'metadata': {
            'name': {
                'uk': 'Сергій',
                'en': 'Segiy'
            }
        }
    }, {
        'id': '6n6ugja4sp',
        'name': 'Наталі',
        'displayName': 'Наталі',
        'photoUrl': 'https://s3.eu-north-1.amazonaws.com/images.fattonysbarbers.com/employees_new_photo/6n6ugja4sp.png',
        'metadata': {
            'name': {
                'uk': 'Наталі',
                'en': 'Natali'
            }
        }
    }, {
        'id': 'yfwbo5lpca',
        'name': 'Іван',
        'displayName': 'Іван',
        'photoUrl': 'https://s3.eu-north-1.amazonaws.com/images.fattonysbarbers.com/employees_new_photo/yfwbo5lpca.png',
        'metadata': {
            'name': {
                'uk': 'Іван',
                'en': 'Ivan'
            }
        }
    }, {
        'id': 'OcW9RSCnrf',
        'name': 'Михайло',
        'displayName': 'Михайло',
        'photoUrl': 'https://s3.eu-north-1.amazonaws.com/images.fattonysbarbers.com/employees_new_photo/OcW9RSCnrf.png',
        'metadata': {
            'name': {
                'uk': 'Михайло',
                'en': 'Mykhaylo'
            }
        }
    }, {
        'id': 'zJj4IWUuQH',
        'name': 'Надія',
        'displayName': 'Надія',
        'photoUrl': 'https://s3.eu-north-1.amazonaws.com/images.fattonysbarbers.com/employees_new_photo/zJj4IWUuQH.png',
        'metadata': {
            'name': {
                'uk': 'Надія',
                'en': 'Nadiya'
            }
        }
    }];
}
